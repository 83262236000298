<template>
  <div class="bg-white dark:bg-gray-800 text-gray-900 dark:text-gray-100">
    <div class="layout contain">
      <div class="flex flex-col justify-start min-h-[120dvh]">
        <header id="app-header" class="page-header">
          <div class="contain-override bg-primary-500 text-white">
            <UnityMenuTop class="contain-xl mb-2" />
          </div>
          <div class="contain-override border-b-4 border-primary-500">
            <UnityMenuMain class="contain-xl my-2" />
          </div>
          <div class="contain-override">
            <UnityMenuTopicPanel class="contain-xl" />
          </div>

          <slot name="header" />
        </header>
        <main
          id="app-main"
          class="page-content flex-grow flex-shrink-0 basis-[60dvh]"
        >
          <slot />
        </main>
        <footer id="app-footer" class="page-footer">
          <UnityFooter />
        </footer>
      </div>
    </div>
    <ScrollToTop />
  </div>
</template>
